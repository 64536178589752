import React from 'react'

const Logo = ({
  width = 183,
  height = 62,
  fill = 'currentColor',
  responsive = true,
}) => {
  return (
    <svg
      width="183"
      height="62"
      viewBox="0 0 117 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M39.4326 22.1642V7.20386H51.6751V9.34105H41.9756V13.5792H51.4571V15.7164H41.9756V20.027H51.7477V22.1642H39.4326Z"
        fill="#1D1A23"
      />
      <path
        d="M53.5641 22.1642V7.20386H58.3594L62.1738 19.5199L65.9882 7.20386H70.3838V22.1642H67.8772V9.30482H67.6229L63.6632 22.1642H60.6843L56.3613 9.30482H56.107V22.1642H53.5641Z"
        fill="#1D1A23"
      />
      <path
        d="M72.3819 22.1642V7.20386H81.6454C84.37 7.20386 85.9684 8.68902 85.9684 11.0436C85.9684 12.9272 85.0239 13.9052 83.2439 14.3399V14.5935C85.1329 14.9557 86.2227 16.1873 86.2227 18.0709C86.2227 20.5341 84.697 22.1642 81.6454 22.1642H72.3819ZM74.9248 13.2532H81.0642C82.6626 13.2532 83.4255 12.6374 83.4255 11.442C83.4255 10.2466 82.7716 9.30482 81.1732 9.30482H74.9611L74.9248 13.2532ZM74.9248 20.027H81.2095C82.9532 20.027 83.6435 19.049 83.6435 17.7087C83.6435 16.3684 82.8443 15.7164 81.1005 15.7164H74.9248V20.027Z"
        fill="#1D1A23"
      />
      <path
        d="M101.916 22.1642V7.20386H111.035C113.977 7.20386 115.721 8.6528 115.721 11.1522C115.757 11.9491 115.503 12.7098 115.031 13.3619C114.522 13.9777 113.832 14.4123 113.033 14.521V14.8108C114.231 15.173 114.885 15.7526 115.212 17.274L116.266 22.2004H113.687L112.851 17.7449C112.56 16.2235 112.088 15.7526 110.235 15.7526H104.459V22.1642H101.916ZM111.035 13.3619C112.451 13.3619 113.105 12.7461 113.105 11.4782C113.105 10.2104 112.488 9.34105 111.144 9.34105H104.423V13.3619H111.035Z"
        fill="#1D1A23"
      />
      <path
        d="M87.7485 22.1642V7.20386H99.9909V9.34105H90.2914V13.5792H99.773V15.7164H90.2914V20.027H100.064V22.1642H87.7485Z"
        fill="#1D1A23"
      />
      <path
        d="M15.3632 3.45611L12.5876 0.117822C12.5485 0.0785479 12.5485 0.0392739 12.5094 0.0392739C12.4313 0 12.3922 0 12.3531 0C12.314 0 12.2749 0 12.2358 0.0392739C12.1967 0.0785479 12.1576 0.0785479 12.1576 0.117822L0.078184 14.6099C0.039092 14.6492 0.039092 14.6885 0 14.7277C0 14.767 0 14.8063 0 14.8848C0 14.9241 0 14.9634 0.039092 15.0026C0.039092 15.0419 0.078184 15.0812 0.117276 15.1205L12.1967 29.6126C12.2358 29.6518 12.2358 29.6911 12.2749 29.6911C12.314 29.7304 12.3531 29.7304 12.3922 29.7304C12.4313 29.7304 12.4703 29.7304 12.5094 29.6911C12.5485 29.6518 12.5876 29.6518 12.5876 29.6126L15.3632 26.2743L5.8638 14.8063L15.3632 3.45611Z"
        fill="#1D1A23"
      />
      <path
        d="M17.0441 24.232L24.7061 15.0812C24.7452 15.0419 24.7452 15.0026 24.7843 14.9634C24.7843 14.9241 24.8234 14.8848 24.8234 14.8063C24.8234 14.767 24.8234 14.7277 24.7843 14.6492C24.7843 14.6099 24.7452 14.5706 24.7061 14.5314L21.8915 11.1538L14.0731 20.6188L17.0441 24.232Z"
        fill="#1D1A23"
      />
      <path
        d="M17.1614 5.5769L9.34299 14.9634L12.314 18.5373L20.1324 9.15083L17.1614 5.5769Z"
        fill="#1D1A23"
      />
    </svg>
  )
}

export default Logo
