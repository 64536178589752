import React from 'react'

const IconArrowheadUp = ({
  width = 10,
  height = 12,
  fill = 'currentColor',
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 12"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <path
        fill={fill}
        d="M.632 5.004a.5.5 0 0 0 .736.677l-.736-.677ZM5 1l.368-.339a.5.5 0 0 0-.735 0L4.999 1Zm3.633 4.681a.5.5 0 0 0 .736-.677l-.736.677ZM4.501 11a.5.5 0 0 0 1 0h-1Zm1-10a.5.5 0 1 0-1 0h1ZM1.368 5.681l4-4.342L4.631.66l-4 4.343.736.677ZM4.632 1.34l4 4.342.736-.677-4-4.343-.736.678ZM5.5 11V1h-1v10h1Z"
      />
    </svg>
  )
}

export default IconArrowheadUp
