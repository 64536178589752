import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { zIndex } from '../../styles/vars/zIndex.style'

export const AppBarMain = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateY(${prop => (prop.hide ? '100%' : '0%')});
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2.4rem;
  z-index: ${zIndex.appBar};
  border-top-left-radius: 1.4rem;
  border-top-right-radius: 1.4rem;
  background-color: ${colors.dark};
  transition: transform 0.3s ${easings.inOut.default};

  ${mq.tabletP} {
    display: none;
  }
`

export const AppBarContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding-right: 5rem;
`

export const AppBarText = styled.p`
  flex: 1;
  margin-left: 1.5rem;
  font-size: 1.2rem;
  line-height: 1.3;
  color: ${colors.light};
`
