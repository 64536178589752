import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import gsap from 'gsap'
import {
  ButtonContent,
  ButtonMain,
  ButtonIcon,
  ButtonText,
  ButtonBackground,
} from './index.style'
import PageTransitionLink from '../PageTransitionLink'

/**
 *
 * @prop type
 * Defines the markup and functionality
 * @prop variant
 * Defines the visual style
 * @prop size
 * Defines the visual size
 * @prop href
 * If defined this renders the component as an anchor element with an href attribute <a href={href} />
 * @prop to
 * If type is internalLink this is the url used in the PageTransitionLink component
 * @prop label
 * If defined this adds an aria-label to the component. Necessary for icon only buttons.
 */

const Button = ({
  type,
  variant,
  size,
  disabled,
  href,
  to,
  fill,
  label,
  submit,
  iconLeft,
  iconRight,
  round,
  roundIconWidth,
  mobilefill,
  onClick,
  children,
  ...props
}) => {
  const $background = useRef()

  const handleMouseMove = e => {
    const btn = e.currentTarget.getBoundingClientRect()
    const x = gsap.utils.clamp(0, btn.width, e.clientX - btn.left)
    const y = gsap.utils.clamp(0, btn.height, e.clientY - btn.top)

    gsap.set($background.current, {
      x,
      xPercent: -50,
      y,
      yPercent: -50,
    })
  }

  return (
    <>
      {type === 'button' && (
        <ButtonMain
          as="button"
          type={submit ? 'submit' : undefined}
          variant={variant}
          size={size}
          round={round}
          mobilefill={mobilefill}
          disabled={disabled}
          aria-label={label}
          onClick={onClick}
          onMouseMove={!isMobile ? handleMouseMove : null}
        >
          <ButtonBackground ref={$background} variant={variant} />

          <ButtonContent>
            {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
            {children && (
              <ButtonText roundIconWidth={roundIconWidth}>
                {children}
              </ButtonText>
            )}
            {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
          </ButtonContent>
        </ButtonMain>
      )}

      {type === 'externalLink' && (
        <ButtonMain
          as="a"
          href={href}
          variant={variant}
          size={size}
          round={round}
          mobilefill={mobilefill}
          aria-label={label}
          onClick={onClick}
          target="_blank"
          rel="noopener, ,noreferrer"
          onMouseMove={!isMobile ? handleMouseMove : null}
          {...props}
        >
          <ButtonBackground ref={$background} variant={variant} />

          <ButtonContent>
            {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
            {children && (
              <ButtonText roundIconWidth={roundIconWidth}>
                {children}
              </ButtonText>
            )}
            {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
          </ButtonContent>
        </ButtonMain>
      )}

      {type === 'internalLink' && (
        <PageTransitionLink
          to={to}
          fill={fill}
          onClick={onClick}
          disabled={disabled}
          mobilefill={mobilefill}
          rounded
        >
          <ButtonMain
            as="div"
            variant={variant}
            size={size}
            round={round}
            disabled={disabled}
            aria-label={label}
            onMouseMove={!isMobile ? handleMouseMove : null}
          >
            <ButtonBackground ref={$background} variant={variant} />

            <ButtonContent>
              {iconLeft && <ButtonIcon left>{iconLeft}</ButtonIcon>}
              {children && (
                <ButtonText roundIconWidth={roundIconWidth}>
                  {children}
                </ButtonText>
              )}
              {iconRight && <ButtonIcon right>{iconRight}</ButtonIcon>}
            </ButtonContent>
          </ButtonMain>
        </PageTransitionLink>
      )}
    </>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(['button', 'internalLink', 'externalLink']).isRequired,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  round: PropTypes.bool,
  submit: PropTypes.bool,
  mobilefill: PropTypes.bool,
  roundIconWidth: function (props, propName) {
    if (
      props['round'] &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a roundIconWidth prop!')
    }
  },
  href: function (props, propName) {
    if (
      props['type'] === 'externalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a href prop!')
    }
  },
  to: function (props, propName) {
    if (
      props['type'] === 'internalLink' &&
      (props[propName] === undefined || typeof props[propName] !== 'string')
    ) {
      return new Error('Please provide a to prop!')
    }
  },
  fill: PropTypes.bool,
  label: PropTypes.string,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  onClick: function (props, propName) {
    if (
      props['type'] === 'button' &&
      (props[propName] === undefined || typeof props[propName] !== 'function')
    ) {
      return new Error('Please provide an onClick function!')
    }
  },
  children: PropTypes.node.isRequired,
}

Button.defaultProps = {
  type: 'button',
  variant: 'primary',
  size: 'medium',
}

export default Button
