import styled from 'styled-components'

export const DotsMain = styled.div`
  width: 100%;
  height: 100%;
`

export const DotsCanvas = styled.canvas`
  width: 100%;
  height: 100%;
`
