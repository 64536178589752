import { css } from 'styled-components'
import { font } from '../vars/font.style'
import { colors } from '../vars/colors.style'
import { transparentize } from 'polished'

export const typography = css`
  html {
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
  }

  body {
    font-size: 1.8rem;
    font-family: Arial, sans-serif;
    font-family: ${font.primary.family}, ${font.fallback.family};
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.dark};
  }

  /* Links */
  a {
    color: ${colors.green};
  }

  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  blockquote {
    padding-left: 0.5em;
    border-left: 0.4rem solid ${transparentize(0.5, colors.dark)};

    .split__text:before,
    .split__text:after {
      content: '"';
      display: inline;
    }
  }

  /* Forms */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  button {
    cursor: pointer;

    :active {
      color: currentColor;
    }
  }

  /* Selection */
  ::-moz-selection {
    background: ${transparentize(0.8, colors.green)};
    color: ${colors.dark};
    text-shadow: none;
  }

  ::selection {
    background: ${transparentize(0.8, colors.green)};
    color: ${colors.dark};
    text-shadow: none;
  }

  /* Focus */
  *:focus {
    outline: none;
  }

  .focus-visible *:focus {
    outline: ${transparentize(0.5, colors.green)} auto 0.4rem;
    outline-style: solid;
  }
`
