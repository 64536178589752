export const zIndex = {
  appBar: 9,
  header: 10,
  nav: 12,
  headerButtons: 13,
  modal: 15,
  cookieBar: 28,
  pageTransitionMask: 30,
  gsDevTools: 40,
}
