import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { clamp } from '../../styles/utils/conversion.style'
import { easings } from '../../styles/vars/easings.style'

export const MenuToggleWrapper = styled.div`
  position: relative;
`

export const MenuToggleBackground = styled.div`
  position: absolute;
  inset: 0;
  background: radial-gradient(
      69.81% 69.81% at 31.13% 30.19%,
      rgba(255, 255, 255, 0.5) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    #f7f8f3;
  box-shadow: 0px 2px 2px rgba(208, 207, 200, 0.51),
    0px 4px 4px rgba(208, 207, 200, 0.4),
    inset 0px -4px 4px rgba(209, 208, 200, 0.2),
    inset 0px 4px 4px rgba(255, 255, 255, 0.25);
  border-radius: 50%;
  will-change: transform;
`

export const MenuToggleButton = styled.button`
  position: relative;
  display: block;
  ${clamp('width', 53, 62)}
  ${clamp('height', 53, 62)}
  z-index: ${zIndex.headerButtons};
  border: none;
  background-color: transparent;
`

export const MenuToggleBar = styled.div`
  position: absolute;
  top: calc(50% - 0.05rem);
  left: 50%;
  width: ${(26 / 62) * 100}%;
  height: 0.2rem;
  margin-left: ${(-(26 / 2) / 62) * 100}%;
  pointer-events: none;
  /* background-color: ${props => (props.open ? colors.light : colors.dark)}; */
  background-color: ${colors.dark};
  border-radius: 10rem;
  transition: background-color 0.3s ${easings.inOut.default};

  :nth-child(1) {
    transform: translateY(-0.3rem);
  }

  :nth-child(2) {
    transform: translateY(0.3rem);
  }
`
