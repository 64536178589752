import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import { mq } from '../../styles/vars/media-queries.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'

export const HeaderContainer = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.header};
`

export const HeaderMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${clamp('padding-top', 12, 24, breakpoints.mobile, breakpoints.tabletL)}
`

export const HeaderLogo = styled.p`
  position: relative;
  ${clamp('width', 156, 183)}
  z-index: ${zIndex.headerButtons};

  ${mq.tabletLMax} {
    color: ${props => (props.navIsOpen ? colors.light : colors.dark)};
  }

  ${mq.tabletL} {
    color: ${colors.dark};
  }

  ::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: ${(62 / 183) * 100}%;
  }

  svg {
    position: absolute;
    inset: 0;
  }

  path {
    transition: color 0.15s ${easings.inOut.default};
  }
`

export const HeaderNav = styled.div`
  display: flex;
  align-items: flex-end;

  a {
    margin-right: ${40 / 14}em;
    margin-bottom: ${5 / 14}em;

    ${mq.mobileLMax} {
      display: none;
    }
  }
`
