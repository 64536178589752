import React, { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { closeNav, openNav, useStore } from '../../Store'
import { disablePageScroll, enablePageScroll } from '../../utils/utils'
import {
  MenuToggleButton,
  MenuToggleBar,
  MenuToggleWrapper,
  MenuToggleBackground,
} from './index.style'
import { colors } from '../../styles/vars/colors.style'

const MenuToggle = () => {
  const [store, dispatch] = useStore()
  const { navIsOpen } = store
  const navIsOpenRef = useRef()
  const $wrapper = useRef()
  const $barTop = useRef()
  const $barBottom = useRef()
  const $background = useRef()

  useEffect(() => {
    const tlOpen = gsap.timeline({ paused: true })
    const tlClose = gsap.timeline({ paused: true, delay: 0.7 })

    tlOpen
      .to(
        [$barTop.current, $barBottom.current],
        {
          backgroundColor: colors.light,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0
      )
      .to(
        $barTop.current,
        {
          y: 0,
          rotate: 45,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )
      .to(
        $barBottom.current,
        {
          y: 0,
          rotate: -45,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    tlClose
      .to(
        [$barTop.current, $barBottom.current],
        {
          backgroundColor: colors.dark,
          duration: 0.3,
          ease: 'power2.inOut',
        },
        0
      )
      .to(
        $barTop.current,
        {
          y: -3,
          rotate: 0,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )
      .to(
        $barBottom.current,
        {
          y: 3,
          rotate: 0,
          duration: 0.2,
          ease: 'power2.inOut',
        },
        0
      )

    if (navIsOpen) {
      navIsOpenRef.current = true
      disablePageScroll()
      tlOpen.play()
    } else {
      navIsOpenRef.current = false
      enablePageScroll()
      tlClose.play()
    }

    return () => {
      tlOpen.kill()
      tlClose.kill()
    }
  }, [navIsOpen])

  const handleClick = () => {
    if (navIsOpen) {
      closeNav(dispatch)
    } else {
      openNav(dispatch)
    }
  }

  return (
    <MenuToggleWrapper ref={$wrapper}>
      <MenuToggleBackground ref={$background} open={navIsOpen} />

      <MenuToggleButton
        onClick={handleClick}
        aria-label={navIsOpen ? 'Close menu' : 'Open menu'}
      >
        <MenuToggleBar ref={$barTop} open={navIsOpen} />
        <MenuToggleBar ref={$barBottom} open={navIsOpen} />
      </MenuToggleButton>
    </MenuToggleWrapper>
  )
}

export default MenuToggle
