import styled from 'styled-components'
import { font } from '../vars/font.style'
import { clamp } from '../utils/conversion.style'
import { mq } from './media-queries.style'
import { breakpoints } from './breakpoints.style'

export const textStylesConfig = {
  heading1: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 46,
      middle: 74,
      max: 105,
    },
    lineHeight: 1,
    letterSpacing: -0.02,
  },
  heading2: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 29,
      middle: 50,
      max: 70,
    },
    lineHeight: 1.1,
  },
  heading3: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 27,
      max: 56,
    },
    lineHeight: 1.2,
  },
  heading4: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 26,
      max: 46,
    },
    lineHeight: 1.2,
  },
  label: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 22,
      max: 28,
    },
    lineHeight: 1.2,
    letterSpacing: -0.01,
  },
  holdingsLabel: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 16,
      max: 28,
    },
    lineHeight: 1.2,
    letterSpacing: -0.01,
  },
  bodyLarge: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 18,
      max: 26,
    },
    lineHeight: 1.4,
  },
  body: {
    family: font.primary.family,
    weight: font.primary.weight.light,
    size: {
      min: 16,
      max: 22,
    },
    lineHeight: 1.4,
  },
  bodySmall: {
    family: font.primary.family,
    weight: font.primary.weight.semibold,
    size: {
      min: 16,
      max: 20,
    },
    lineHeight: 1.2,
  },
  bodyXSmall: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 14,
      max: 16,
    },
    lineHeight: 1.4,
  },
  bodyXXSmall: {
    family: font.primary.family,
    weight: font.primary.weight.regular,
    size: {
      min: 12,
      max: 14,
    },
    lineHeight: 1.4,
  },
  cta: {
    family: font.primary.family,
    weight: font.primary.weight.semibold,
    size: {
      min: 16,
      max: 22,
    },
    lineHeight: 1.04,
    letterSpacing: -0.01,
  },
}

export const getStyles = style => `
  font-family: ${style.family};
  font-weight: ${style.weight};
  line-height: ${style.lineHeight};
  ${style.letterSpacing ? `letter-spacing: ${style.letterSpacing}em` : ''};
  ${style.uppercase ? 'text-transform: uppercase' : ''};
`

export const getFontSize = style => `
  ${clamp('font-size', style.size.min, style.size.max)}
`

export const Heading1 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.heading1)}
  color: ${props => (props.color ? props.color : undefined)};

  ${mq.deskMMax} {
    ${clamp(
      'font-size',
      textStylesConfig.heading1.size.min,
      textStylesConfig.heading1.size.middle,
      breakpoints.mobile,
      breakpoints.deskM
    )}
  }

  ${mq.deskM} {
    ${clamp(
      'font-size',
      textStylesConfig.heading1.size.middle,
      textStylesConfig.heading1.size.max,
      breakpoints.deskM,
      breakpoints.deskL
    )}
  }
`

export const Heading2 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getStyles(textStylesConfig.heading2)}
  color: ${props => (props.color ? props.color : undefined)};

  ${mq.deskMMax} {
    ${clamp(
      'font-size',
      textStylesConfig.heading2.size.min,
      textStylesConfig.heading2.size.middle,
      breakpoints.mobile,
      breakpoints.deskM
    )}
  }

  ${mq.deskM} {
    ${clamp(
      'font-size',
      textStylesConfig.heading2.size.middle,
      textStylesConfig.heading2.size.max,
      breakpoints.deskM,
      breakpoints.deskL
    )}
  }
`

export const Heading3 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.heading3)}
  ${getStyles(textStylesConfig.heading3)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Heading4 = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.heading4)}
  ${getStyles(textStylesConfig.heading4)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const Label = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.label)}
  ${getStyles(textStylesConfig.label)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const HoldingsLabel = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.holdingsLabel)}
  ${getStyles(textStylesConfig.holdingsLabel)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyLarge = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.bodyLarge)}
  ${getStyles(textStylesConfig.bodyLarge)}
  color: ${props => (props.color ? props.color : undefined)};
  opacity: 0.61;
`

export const TextBody = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.body)}
  ${getStyles(textStylesConfig.body)}
  color: ${props => (props.color ? props.color : undefined)};
  opacity: 0.61;
`

export const TextBodySmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.bodySmall)}
  ${getStyles(textStylesConfig.bodySmall)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyXSmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.bodyXSmall)}
  ${getStyles(textStylesConfig.bodyXSmall)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextBodyXXSmall = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.bodyXXSmall)}
  ${getStyles(textStylesConfig.bodyXXSmall)}
  color: ${props => (props.color ? props.color : undefined)};
`

export const TextCta = styled.p`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}em` : undefined)};
  ${getFontSize(textStylesConfig.cta)}
  ${getStyles(textStylesConfig.cta)}
  color: ${props => (props.color ? props.color : undefined)};
`
