import styled, { css } from 'styled-components'
import { font } from '../../styles/vars/font.style'
import { colors } from '../../styles/vars/colors.style'
import { clamp } from '../../styles/utils/conversion.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'

export const ButtonMain = styled.button`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: 1;

  ${({ mobilefill }) => {
    if (mobilefill) {
      return `
        ${mq.mobileLMax} {
          width: 100%;
        }
      `
    }
  }}

  ${({ round }) =>
    round
      ? `
    ${clamp('width', 45, 61)}
    ${clamp('height', 45, 61)}
  `
      : undefined};

  ${({ round, size }) => {
    let padding = '0.8181818182em 1.3636363636em'

    if (round) {
      padding = '0'
    } else if (size === 'small') {
      padding = '0.8181818182em 2.1428571429em'
    }

    return `padding: ${padding};`
  }}

  overflow: hidden;
  cursor: pointer;
  will-change: transform;

  ${({ size }) => {
    if (size === 'small') {
      return `
        font-size: 1.4rem;
      `
    }

    if (size === 'medium') {
      return clamp('font-size', 16, 22)
    }
  }}

  font-family: ${font.primary.family};
  font-weight: ${font.primary.weight.semibold};
  letter-spacing: -0.01em;
  color: currentColor;
  text-align: center;
  text-decoration: none;
  border: none;
  ${clamp('border-radius', 74, 100)}

  &[disabled] {
    cursor: default;
    color: ${colors.light};
    background-color: ${colors.greyDark};
    border-color: ${colors.grey};
    pointer-events: none;
  }

  ${({ variant }) => {
    if (variant === 'primary') {
      return ButtonPrimary
    }

    if (variant === 'secondary') {
      return ButtonSecondary
    }
  }}
`

const ButtonPrimary = css`
  color: ${colors.light};
  background-color: ${colors.green};
  border: 0.1rem solid ${colors.green};

  ${mq.desk} {
    :not(:disabled):hover,
    :not(:disabled):focus {
      color: ${colors.dark};
    }
  }
`

const ButtonSecondary = css`
  color: ${colors.dark};
  background-color: transparent;
  box-shadow: 0 0 0 0.1rem ${colors.green} inset;

  ${mq.desk} {
    :not(:disabled):hover,
    :not(:disabled):focus {
      color: ${colors.light};
    }
  }
`

export const ButtonContent = styled.span`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 100%;
  }
`

export const ButtonIcon = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1em;
  margin-left: ${props => (props.right ? '0.5em' : undefined)};
  margin-right: ${props => (props.left ? '0.5em' : undefined)};

  path {
    transition: color 0.2s ${easings.inOut.default} 0.1s;
  }
`

export const ButtonText = styled.span`
  width: ${props => (props.roundIconWidth ? props.roundIconWidth : undefined)};
  line-height: 1;
  transition: color 0.2s ${easings.inOut.default} 0.05s;
`

export const ButtonBackground = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 202%;
  pointer-events: none;
  background-color: ${({ variant }) => {
    if (variant === 'primary') {
      return colors.light
    }

    if (variant === 'secondary') {
      return colors.green
    }
  }};
  clip-path: circle(0% at 50% 50%);
  transition: clip-path 0.3s ${easings.out.default};
  will-change: transform;

  ${mq.desk} {
    ${ButtonMain}:not(:disabled):hover &,
    ${ButtonMain}:not(:disabled):focus & {
      clip-path: circle(50% at 50% 50%);
      transition: clip-path 1s ${easings.out.cubic};
    }
  }

  :before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
  }
`
