import styled, { css } from 'styled-components'
import { clamp, setVh } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'
import { mq } from '../../styles/vars/media-queries.style'
import { zIndex } from '../../styles/vars/zIndex.style'
import {
  ContainerMain,
  matchContainerPadding,
  paddingMax,
} from '../Container/index.style'
import { GridMain } from '../Grid/index.style'
import { GridItemMain } from '../GridItem/index.style'
import { SpacerMain } from '../Spacer/index.style'

export const MenuBackground = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  ${matchContainerPadding('left', true)}
  ${matchContainerPadding('right', true)}
  background-color: ${colors.dark};

  ${mq.tabletL} {
    right: auto;
    left: 0;
    right: ${-paddingMax / 10}rem;
  }

  ${mq.contained} {
    right: calc(-50vw + ${breakpoints.contained / 2}px - ${paddingMax}px);
  }
`

export const NavMain = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  ${setVh('height', 100, true)}
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: ${zIndex.nav};
  pointer-events: ${props => (props.open ? undefined : 'none')};
  color: ${colors.dark};
  transition: transform 0.2s ${easings.inOut.default};

  ${ContainerMain}:nth-child(3) {
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    ${MenuBackground} {
      ${mq.tabletL} {
        display: none;
      }
    }

    ${mq.desk} {
      overflow: visible;
    }
  }

  ${GridMain} {
    width: 100%;

    ${mq.tabletL} {
      height: 100%;
    }
  }

  ${GridItemMain} {
    position: relative;
  }
`

export const NavBackground = styled.div`
  height: 200%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${colors.light};
  opacity: 0;

  ${mq.desk} {
    bottom: 0;
    height: auto;
  }
`

export const MenuWrapper = styled.div`
  position: relative;
  height: 100%;

  ${mq.tabletLMax} {
    padding-top: 13.8rem;
    padding-bottom: 7rem;
  }
`

export const MenuBackgroundWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  ${ContainerMain},
  ${GridMain},
  ${GridItemMain} {
    height: 100%;
  }

  ${GridItemMain} {
    position: relative;
  }

  ${MenuBackground} {
    ${mq.tabletLMax} {
      display: none;
    }
  }
`

export const Menu = styled.ul`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  list-style: none;
  color: ${colors.light};
`

export const MenuItem = styled.li`
  position: relative;
  ${clamp('font-size', 40, 56)}
  line-height: 1.2;

  ${mq.shortDesk} {
    ${clamp('font-size', 20, 36)}
  }

  a {
    display: block;
    ${clamp('padding-top', 22, 35)}
    ${clamp('padding-bottom', 22, 35)}
    color: ${colors.light};

    ${mq.shortDesk} {
      ${clamp('padding-top', 12, 22)}
      ${clamp('padding-bottom', 12, 22)}
    }

    :hover,
    :focus {
      color: ${colors.green};
    }
  }
`

export const MenuItemMask = styled.span`
  display: block;
  overflow: hidden;
`

export const MenuItemText = styled.span`
  display: block;
  transition: color 0.4s ${easings.inOut.default};

  :before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    display: block;
    ${clamp('width', 6, 8)}
    ${clamp('height', 6, 8)}
    border-radius: 50%;
    background-color: ${colors.green};
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 0.15s ${easings.inOut.default};
  }
`

export const MenuItemBorder = styled.div`
  height: 0.1rem;
  opacity: 0.1;
  background-color: ${colors.light};
`

const DotStyles = css`
  position: absolute;
  ${clamp('width', 23, 44)}
`

export const DotA = styled.div`
  ${DotStyles}

  ${mq.tabletLMax} {
    transform: translateX(50%);
    top: 50%;
    ${matchContainerPadding('right', true)}
  }

  ${mq.tabletL} {
    right: 10%;
    bottom: 7.94%;
  }
`

export const DotB = styled.div`
  ${DotStyles}

  ${mq.tabletLMax} {
    right: 2rem;
    top: 75%;
  }

  ${mq.tabletL} {
    transform: translateX(-100%);
    top: 14%;
    left: 14%;
  }
`

export const DotC = styled.div`
  ${DotStyles}
  transform-origin: 0% 100%;

  ${mq.tabletLMax} {
    display: none;
  }

  ${mq.tabletL} {
    transform: rotate(90deg);
    top: 20%;
    ${matchContainerPadding('left', true)}
  }
`

export const MenuFooter = styled.div`
  padding-top: 3.2rem;
  padding-bottom: 3rem;

  ${mq.tabletL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  ${mq.shortDesk} {
    ${SpacerMain} {
      min-height: 3rem;
    }
  }
`

export const MenuTextMask = styled.div`
  overflow: hidden;
`

export const MenuCTAs = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const MenuCTA = styled.a`
  display: block;
  ${clamp('width', 160, 197)}
  ${clamp('margin-top', 7, 20)}
  
  :first-child {
    ${clamp('margin-right', 7, 20)}
  }

  ${mq.shortDesk} {
    width: 12rem;
  }
`

export const MenuSocials = styled.ul`
  list-style: none;

  ${mq.tabletLMax} {
    columns: ${props => (props.columns ? '10rem 2' : undefined)};
  }
`

export const MenuSocialsItem = styled.li`
  :not(:last-child) {
    margin-bottom: 2rem;
  }

  ${mq.shortDesk} {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 2rem;
  }
`

export const MenuSocialsLink = styled.a`
  display: block;
  overflow: hidden;
  color: ${colors.dark};
  text-decoration: none;
  transition: color 0.15s ${easings.inOut.default};

  :hover {
    color: ${colors.green};
  }
`
