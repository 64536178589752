import { transparentize } from 'polished'
import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { GridMain } from '../Grid/index.style'
import { GridItemMain } from '../GridItem/index.style'
import { FadeInWrapper } from '../animation/AnimateFadeIn/index.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const FooterCTA = styled.div`
  position: relative;
  text-align: center;
  border-top: 0.1rem solid ${transparentize(0.9, colors.dark)};
`

export const FooterCtaInner = styled.div`
  position: relative;
`

export const FooterCtaCanvas = styled.div`
  position: absolute;
  ${clamp('top', 20, 50)}
  ${clamp('bottom', 20, 50)}
  right: 0;
  left: 0;
`

export const FooterCtaContentMask = styled.div`
  display: inline-flex;

  ${mq.mobileLMax} {
    width: 100%;
    justify-content: center;

    ${FadeInWrapper} {
      width: 100%;
    }
  }
`

export const FooterWrapper = styled.div`
  padding-bottom: 11.7rem;

  ${mq.tabletP} {
    padding-bottom: 2.4rem;
  }
`

export const FooterMain = styled.footer`
  background-color: ${colors.dark};
  border-radius: 1.5rem;
  color: ${colors.light};

  a {
    display: block;
    color: ${colors.light};

    ${mq.tabletPMax} {
      padding-top: 2rem;
    }
  }
`

export const FooterContent = styled.div`
  padding: 18rem 3rem 4rem;

  ${mq.tabletPMax} {
    ${GridMain} {
      grid-template-columns: repeat(12, 1fr);
    }

    ${GridItemMain} {
      grid-column: 1 / span 12;
    }
  }

  ${mq.tabletP} {
    padding: 6rem 0 18rem;
  }

  a {
    transition: opacity 0.15s ${easings.inOut.default};

    :hover,
    :focus {
      opacity: 0.5;
    }
  }
`

export const FooterLinks = styled.div`
  columns: 2;

  ${mq.tabletPMax} {
    margin-top: -2rem;
  }

  ${mq.tabletP} {
    margin-top: -1rem;

    ${PageTransitionLinkMain} {
      padding-top: 1rem;
    }
  }

  p {
    opacity: 1;
  }
`

export const FooterSocials = styled.div`
  ${mq.tabletPMax} {
    columns: 2;
    margin-top: 2rem;
  }

  ${mq.tabletP} {
    a + a {
      margin-top: 2rem;
    }
  }
`

export const FooterCopyright = styled.div`
  position: relative;
  padding-left: 3rem;
  padding-right: 10rem;
  border-top: 0.1rem solid ${transparentize(0.9, colors.light)};

  ${mq.tabletP} {
    padding-right: 3rem;
    text-align: center;
  }

  p {
    opacity: 0.2;
  }
`

export const FooterScrollToTop = styled.button`
  position: absolute;
  top: 50%;
  right: 3rem;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  border: none;
  background-color: transparent;

  ${mq.tabletP} {
    display: none;
  }
`
