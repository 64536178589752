import React from 'react'
import { colors } from '../../../styles/vars/colors.style'
import { DotHalfMain } from './index.style'

const DotHalf = ({ minWidth = 22, maxWidth = 44, fill = colors.green }) => (
  <DotHalfMain minWidth={minWidth} maxWidth={maxWidth}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 22"
      aria-hidden="true"
      focusable="false"
    >
      <path fill={fill} d="M44 22a22 22 0 0 0-44 0h44Z" />
    </svg>
  </DotHalfMain>
)

export default DotHalf
