import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { breakpoints } from '../../styles/vars/breakpoints.style'

export const paddingMin = 24
export const paddingMax = 32

export const matchContainerPadding = (property, invert = false) => {
  const min = invert ? -paddingMin : paddingMin
  const max = invert ? -paddingMax : paddingMax

  return `
  ${clamp(property, min, max, breakpoints.mobile, breakpoints.tabletL)}
`
}

export const ContainerMain = styled.div`
  width: 100%;
  max-width: ${props =>
    props.fullWidth
      ? undefined
      : `${(paddingMax * 2 + breakpoints.contained) / 10}rem`};
  margin: 0 auto;
  ${matchContainerPadding('padding-left')}
  ${matchContainerPadding('padding-right')}
  overflow: ${props => (props.allowOverflow ? undefined : 'hidden')};
`
