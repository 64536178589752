import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PageTransitionLink from '../PageTransitionLink'
import Container from '../Container'
import MenuToggle from '../MenuToggle'
import { useStore } from '../../Store'
import {
  HeaderContainer,
  HeaderLogo,
  HeaderMain,
  HeaderNav,
} from './index.style'
import Logo from '../svgs/Logo'
import Nav from '../Nav'
import LinkButton from '../LinkButton'

const Header = ({ pathname }) => {
  const { contentfulGlobals } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        headerDownloadTarget {
          ... on Node {
            ...ContentfulLink
          }
        }
      }
    }
  `)
  const { headerDownloadTarget } = contentfulGlobals
  const [store] = useStore()
  const { navIsOpen } = store

  return (
    <HeaderContainer>
      <Container fullWidth={true}>
        <HeaderMain>
          <PageTransitionLink id="home-logo" to="/">
            <HeaderLogo aria-label="Ember Fund" navIsOpen={navIsOpen}>
              <Logo />
            </HeaderLogo>
          </PageTransitionLink>

          <HeaderNav>
            <LinkButton
              text="Download app"
              target={headerDownloadTarget}
              variant="secondary"
              size="small"
            />
            <MenuToggle />
          </HeaderNav>
        </HeaderMain>
      </Container>

      <Nav pathname={pathname} />
    </HeaderContainer>
  )
}

export default Header
