import React from 'react'
import { colors } from '../../../styles/vars/colors.style'
import { DotMain } from './index.style'

const Dot = ({ minWidth = 22, maxWidth = 44, fill = colors.green }) => (
  <DotMain minWidth={minWidth} maxWidth={maxWidth}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 44 44"
      aria-hidden="true"
      focusable="false"
    >
      <circle cx="22" cy="22" r="22" fill={fill} />
    </svg>
  </DotMain>
)

export default Dot
