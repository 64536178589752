import React, { useState, useEffect, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { AppBarContent, AppBarMain, AppBarText } from './index.style'
import Button from '../Button'
import Logomark from '../svgs/Logomark'

const AppBar = ({ pathname }) => {
  const {
    contentfulGlobals: { appBarText },
  } = useStaticQuery(graphql`
    query {
      contentfulGlobals {
        appBarText
      }
    }
  `)
  const hideOnLoad = pathname.indexOf('blog') > -1 ? true : false
  const [scrolling, setScrolling] = useState(hideOnLoad)
  const hide = useRef(hideOnLoad)
  const isScrolling = useRef(hideOnLoad)
  const timeout = useRef()

  useEffect(() => {
    setScrolling(true)
    hide.current = pathname.indexOf('blog') > -1 ? true : false
  }, [pathname])

  useEffect(() => {
    const handleScroll = e => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      if (!isScrolling.current) {
        setScrolling(true)
        isScrolling.current = true
      }

      timeout.current = setTimeout(() => {
        if (hide.current) return
        setScrolling(false)
        isScrolling.current = false
      }, 200)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <AppBarMain hide={scrolling}>
      <AppBarContent>
        <Logomark responsive={false} />

        <AppBarText>{appBarText}</AppBarText>
      </AppBarContent>

      <Button
        href="https://emberfund.onelink.me/ljTI/emberfundhome"
        type="externalLink"
      >
        Get Ember
      </Button>
    </AppBarMain>
  )
}

export default AppBar
