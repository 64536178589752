import React, { useReducer, createContext, useContext } from 'react'

export const StoreContext = createContext()
StoreContext.displayName = 'StoreContext'

const initialState = {
  showPageMask: true,
  navIsOpen: false,
  fontIsLoaded: false,
  pathname: typeof window === 'undefined' ? null : window.location.pathname,
  popstate: 0,
}

function storeReducer(state, action) {
  switch (action.type) {
    case 'UPDATE_PATHNAME': {
      return {
        ...state,
        pathname: action.pathname,
      }
    }

    case 'UPDATE_POP_STATE': {
      return {
        ...state,
        popstate: state.popstate + 1,
      }
    }

    case 'SHOW_PAGE_MASK': {
      return {
        ...state,
        showPageMask: true,
      }
    }
    case 'HIDE_PAGE_MASK': {
      return {
        ...state,
        showPageMask: false,
      }
    }

    case 'LOAD_FONTS': {
      return {
        ...state,
        fontIsLoaded: true,
      }
    }

    case 'OPEN_NAV': {
      return {
        ...state,
        navIsOpen: true,
      }
    }
    case 'CLOSE_NAV': {
      return {
        ...state,
        navIsOpen: false,
      }
    }

    default:
      throw new Error('Bad action type')
  }
}

export const updatePathname = (dispatch, pathname) =>
  dispatch({ type: 'UPDATE_PATHNAME', pathname })
export const updatePopState = dispatch => dispatch({ type: 'UPDATE_POP_STATE' })
export const showPageMask = dispatch => dispatch({ type: 'SHOW_PAGE_MASK' })
export const hidePageMask = dispatch => dispatch({ type: 'HIDE_PAGE_MASK' })
export const loadFonts = dispatch => dispatch({ type: 'LOAD_FONTS' })
export const openNav = dispatch => dispatch({ type: 'OPEN_NAV' })
export const closeNav = dispatch => dispatch({ type: 'CLOSE_NAV' })

function StoreProvider(props) {
  const [store, dispatch] = useReducer(storeReducer, initialState)
  const value = [store, dispatch]

  return <StoreContext.Provider value={value} {...props} />
}

export function useStore() {
  const context = useContext(StoreContext)

  if (!context) {
    throw new Error('useStore must be used within a StoreProvider')
  }

  return context
}

function Store(props) {
  return <StoreProvider>{props.children}</StoreProvider>
}

export default Store
