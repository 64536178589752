import React, { useCallback, useRef, useEffect, useState } from 'react'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import { useStore } from '../../../Store'
import { SplitText as gsapSplitText } from 'gsap/SplitText'
import PropTypes from 'prop-types'
import { SplitTextWrapper } from './index.style'
import debounce from 'lodash.debounce'

gsap.registerPlugin(gsapSplitText)

const AnimateSplitText = ({
  children,
  type = 'lines',
  delay = 0,
  triggerOnce = true,
  animate = true,
}) => {
  const [store] = useStore()
  const { showPageMask } = store
  const contentRef = useRef()
  const splitContentRef = useRef()
  const timeout = useRef()
  const [isSplit, setIsSplit] = useState(false)

  const childrenArray = children?.props ? children.props.children : children
  const ariaLabel =
    typeof children === 'object'
      ? React.Children.toArray(childrenArray)
          .filter(item => (typeof item === 'object' ? '' : item))
          .join('')
      : children

  const build = useCallback(() => {
    if (!contentRef.current) return

    splitContentRef.current = new gsapSplitText(contentRef.current, {
      type: type,
      span: true,
    })

    splitContentRef.current[type].forEach((item, itemIndex) => {
      item.classList.add('split__mask')
      item.setAttribute('aria-hidden', true)
      item.style.setProperty('--splitTextDelay', `${itemIndex}s`)
      item.innerHTML = `<span class="split__text">${item.innerHTML}</span>`
    })

    timeout.current = setTimeout(() => {
      setIsSplit(true)
    }, 10)
  }, [type])

  const update = useCallback(() => {
    splitContentRef.current.revert()
    build()
  }, [build])

  const [inViewRef, inView] = useInView({ triggerOnce })

  const setRefs = useCallback(
    node => {
      inViewRef(node)
      contentRef.current = node
    },
    [inViewRef]
  )

  useEffect(() => {
    if (store.fontIsLoaded) {
      build()

      window.addEventListener('resize', debounce(update, 100))
    }
  }, [store.fontIsLoaded, build, update])

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      window.removeEventListener('resize', update)
    }
  }, [update])

  return (
    <SplitTextWrapper
      ref={setRefs}
      show={isSplit && inView && animate && !showPageMask}
      delay={delay}
      aria-label={ariaLabel}
      type={type}
    >
      {children}
    </SplitTextWrapper>
  )
}

AnimateSplitText.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  triggerOnce: PropTypes.bool,
  animate: PropTypes.bool,
  type: PropTypes.oneOf(['lines', 'words', 'chars', 'words,chars']),
}

export default AnimateSplitText
