import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'
import { animation } from '../../../styles/vars/animation.style'
import { IconMain } from '../../Icon/index.style'

export const SplitTextWrapper = styled.span`
  display: block;

  .split__mask {
    padding-bottom: 0.1em;
    overflow: hidden;

    :not(:first-child) {
      margin-top: -0.1em;
    }

    :last-child {
      margin-bottom: -0.1em;
    }
  }

  .split__text {
    display: inline-block;
    will-change: transform;
    transform: ${props => (props.show ? 'translateY(0)' : 'translateY(100%)')};
    opacity: ${props => (props.show ? 1 : 0)};
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ${easings.inOut.default};
    transition-delay: ${props =>
      props.show &&
      `calc(${props.delay}s + ${
        props.type === 'lines' ? animation.lineDuration : animation.wordDuration
      } * var(--splitTextDelay))`};

    ${IconMain} {
      vertical-align: text-top;
    }
  }
`
