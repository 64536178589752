import React from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { closeNav, showPageMask, updatePathname, useStore } from '../../Store'
import { animation } from '../../styles/vars/animation.style'
import { PageTransitionLinkMain } from './index.style'

const PageTransitionLink = ({
  children,
  to,
  fill = false,
  disabled,
  rounded,
  onClick,
  ...props
}) => {
  const [store, dispatch] = useStore()
  const { pathname, navIsOpen } = store
  const $homeLogo =
    typeof document !== 'undefined'
      ? document.getElementById('home-logo')
      : null

  return (
    <PageTransitionLinkMain
      to={to}
      exit={{
        delay: animation.maskShowDuration,
        length: 0,
        zIndex: 2,
      }}
      entry={{
        delay: 0,
        length: animation.maskHideDuration,
        zIndex: 1,
        trigger: () => {
          if (!isMobile) $homeLogo.focus()
        },
      }}
      fill={fill ? 'true' : undefined}
      {...props}
      onClick={() => {
        if (onClick) {
          onClick()
        }

        if (to.indexOf(pathname) === -1 || to.length !== pathname.length) {
          updatePathname(dispatch, to)

          if (!navIsOpen) {
            showPageMask(dispatch)
          } else {
            closeNav(dispatch)
          }
        }
      }}
      disabled={disabled}
      rounded={rounded ? 'true' : undefined}
    >
      {children}
    </PageTransitionLinkMain>
  )
}

PageTransitionLink.propTypes = {
  children: PropTypes.node,
  fill: PropTypes.bool,
  disabled: PropTypes.bool,
  rounded: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
}

export default PageTransitionLink
