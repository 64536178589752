import React, { useState, useEffect, useCallback } from 'react'
import gsap from 'gsap'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import FontFaceObserver from 'fontfaceobserver'

// Components
import {
  useStore,
  updatePathname,
  updatePopState,
  loadFonts,
} from '../../Store'
import TransitionMask from '../PageTransitionMask'
import Gridlines from '../Gridlines'
import Header from '../Header'
// import CookieBar from '../CookieBar'

// Fonts
// import RMNeueLightWoff2 from 'https://emberfund.io/static/RMNeue/RMNeue-Light.woff2'
// import RMNeueLightWoff from 'https://emberfund.io/static/RMNeue/RMNeue-Light.woff'
// import RMNeueRegularWoff2 from 'https://emberfund.io/static/RMNeue/RMNeue-Regular.woff2'
// import RMNeueRegularWoff from 'https://emberfund.io/static/RMNeue/RMNeue-Regular.woff'
// import RMNeueRegularItalicWoff2 from 'https://emberfund.io/static/RMNeue/RMNeue-Italic.woff2'
// import RMNeueRegularItalicWoff from 'https://emberfund.io/static/RMNeue/RMNeue-Italic.woff'
// import RMNeueSemiboldWoff2 from 'https://emberfund.io/static/RMNeue/RMNeue-SemiBold.woff2'
// import RMNeueSemiboldWoff from 'https://emberfund.io/static/RMNeue/RMNeue-SemiBold.woff'

// Styles
import { GlobalStyles } from '../../styles/GlobalStyles.style'
import { font } from '../../styles/vars/font.style'
import Footer from '../Footer'
import AppBar from '../AppBar'

const Layout = ({ children, location }) => {
  const [, dispatch] = useStore()
  const [keyMode, setKeyMode] = useState(false)

  const handleBrowserNavigationInteraction = useCallback(
    e => {
      updatePathname(dispatch, window.location.pathname)
      updatePopState(dispatch)
    },
    [dispatch]
  )

  const setViewportHeight = () => {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const handleWebfontLoad = useCallback(() => {
    const light = new FontFaceObserver(font.primary.family, {
      weight: font.primary.weight.light,
    })
    const regular = new FontFaceObserver(font.primary.family, {
      weight: font.primary.weight.regular,
    })
    const semibold = new FontFaceObserver(font.primary.family, {
      weight: font.primary.weight.semibold,
    })

    Promise.all([light.load(), regular.load(), semibold.load()])
      .then(() => {
        gsap.delayedCall(0.2, () => {
          loadFonts(dispatch)
        })
      })
      .catch(err => {
        console.warn('Some critical font are not available', err)
      })
  }, [dispatch])

  const setMouseMode = useCallback(() => {
    if (!keyMode) return
    setKeyMode(false)
    document.body.classList.remove('focus-visible')
  }, [keyMode])

  const setKeyboardMode = useCallback(() => {
    if (keyMode) return
    setKeyMode(true)
    document.body.classList.add('focus-visible')
  }, [keyMode])

  useEffect(() => {
    setViewportHeight()
    handleWebfontLoad()

    window.addEventListener('resize', setViewportHeight)
    window.addEventListener('popstate', handleBrowserNavigationInteraction)
    document.body.addEventListener('click', setMouseMode)
    document.body.addEventListener('keydown', setKeyboardMode)

    return () => {
      window.removeEventListener('resize', setViewportHeight)
      window.removeEventListener('popstate', handleBrowserNavigationInteraction)
      document.body.removeEventListener('click', setMouseMode)
      document.body.removeEventListener('keydown', setKeyboardMode)
    }
  }, [
    dispatch,
    handleWebfontLoad,
    handleBrowserNavigationInteraction,
    setMouseMode,
    setKeyboardMode,
  ])

  return (
    <>
      <GlobalStyles />
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-144562084-1"
        ></script>
        <script>{`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'UA-144562084-1');
        `}</script>
        <script src="https://www.googleoptimize.com/optimize.js?id=OPT-WJJZV4P"></script>
        <script async src="https://js.stripe.com/v3/pricing-table.js"></script>

        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#00de3e" />
        <meta name="theme-color" content="#ffffff" />
        <style>{`
          @font-face {
            font-family: '${font.primary.family}';
            src: url('https://emberfund.io/fonts/Inter/Inter-Light.woff2') format('woff2'),
                url('https://emberfund.io/fonts/Inter/Inter-Light.woff') format('woff');
            font-weight: ${font.primary.weight.light};
            font-style: normal;
          }
          @font-face {
            font-family: '${font.primary.family}';
            src: url('https://emberfund.io/fonts/Inter/Inter-Regular.woff2') format('woff2'),
                url('https://emberfund.io/fonts/Inter/Inter-Regular.woff') format('woff');
            font-weight: ${font.primary.weight.regular};
            font-style: normal;
          }
          @font-face {
            font-family: '${font.primary.family}';
            src: url('https://emberfund.io/fonts/Inter/Inter-SemiBold.woff2') format('woff2'),
                url('https://emberfund.io/fonts/Inter/Inter-SemiBold.woff') format('woff');
            font-weight: ${font.primary.weight.semibold};
            font-style: normal;
          }
        `}</style>
      </Helmet>

      <Gridlines show={false} />

      <TransitionMask />

      <div id="scroll-container" data-scroll-container>
        <Header pathname={location.pathname} />

        <AppBar pathname={location.pathname} />

        {children}

        <Footer />

        {/* <CookieBar /> */}
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
