import { createGlobalStyle } from 'styled-components'
import { reset } from './partials/reset.style'
import { typography } from './partials/typography.style'
import { locomotiveScrollStyles } from './partials/locomotiveScroll.style'
import { gatsbyTransitionLinkStyles } from './partials/gatsbyTransitionLink.style'
import { colors } from './vars/colors.style'
import { zIndex } from './vars/zIndex.style'
import { mq } from './vars/media-queries.style'

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${typography}
  ${locomotiveScrollStyles}
  ${gatsbyTransitionLinkStyles}

  :root {
    --reach-tabs: 1; // This disables the Reach UI warning about not including matching CSS files https://reach.tech/styling/
    --reach-dialog: 1;
  }

  * {
    -webkit-marquee-increment: 0vw; // This ensures that clamp values are updated on window resize in Safari
  }

  html,
  body {
    &.disable-scroll {
      overflow: hidden;
      overscroll-behavior: none;
      touch-action: none;
      height: calc((var(--vh) * 100) - 1px);

      ${mq.desk} {
        height: auto;
      }
    }
  }

  body {
    background-color: ${colors.light};
  }
  
  [data-reach-dialog-overlay] {
    z-index: ${zIndex.modal};
  }

  .gs-dev-tools {
    z-index: ${zIndex.gsDevTools};
  }
`
