import React from 'react'
import PropTypes from 'prop-types'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import Button from '../Button'

const LinkButton = ({
  text,
  target,
  variant = 'primary',
  animate = true,
  mobilefill,
  size,
  delay,
}) => (
  <AnimateFadeIn animate={animate} delay={delay}>
    {target.url ? (
      <Button
        type="externalLink"
        href={target.url}
        variant={variant}
        size={size}
        mobilefill={mobilefill}
      >
        {text}
      </Button>
    ) : (
      <Button
        type="internalLink"
        to={`${target.page.slug}/`}
        variant={variant}
        size={size}
        mobilefill={mobilefill}
      >
        {text}
      </Button>
    )}
  </AnimateFadeIn>
)

LinkButton.propTypes = {
  text: PropTypes.string,
  target: PropTypes.object,
  mobilefill: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  animate: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium']),
  delay: PropTypes.number,
}

export default LinkButton
