export const colors = {
  dark: '#1D1A23',
  light: '#FEFFF9',
  light2: '#F0F1EC',
  grey: '#F5F6F1',
  greyMid3: '#EAEBE7',
  greyMid: '#E4E6E0',
  greyMid2: '#E9E9E9',
  greyDark: '#C1C1C1',
  green: '#00DE3E',
  red: '#ff4a4a',
}
