import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import useCanvas from '../../hooks/useCanvas'
import useMousePosition from '../../hooks/useMousePosition'
import { DotsCanvas, DotsMain } from './index.style'
import { colors } from '../../styles/vars/colors.style'
import gsap from 'gsap'
import { hexToRgbA } from '../../styles/utils/conversion.style'

const Dots = ({
  showBackgroundDots = true,
  shimmer = true,
  shimmerAmount = 1,
  lerpOpacityScale = 1,
  content,
}) => {
  const { $canvas, ctx, canvasDimensions } = useCanvas()
  const mousePos = useMousePosition()
  const [inViewRef, inView] = useInView()
  const isInView = useRef(inView)
  const animationFrame = useRef()
  const dotsArray = useRef([])
  const mousePosPrevious = useRef({ x: 0, y: 0 })
  const dotDiameter = useRef(4)
  const contentRef = useRef(content)
  const dotRadius = useRef(dotDiameter.current / 2)
  const canvasRect = useRef(null)
  const shimmerThreshhold = 0.0004 * shimmerAmount

  // Check if inView
  useEffect(() => {
    isInView.current = inView
  }, [inView])

  useEffect(() => {
    canvasRect.current = $canvas.current.getBoundingClientRect()
  }, [$canvas, mousePos])

  // Setup dots array
  useEffect(() => {
    if (!canvasDimensions) return

    const setup = () => {
      canvasRect.current = $canvas.current.getBoundingClientRect()

      const dotGap = 26
      const numRows = Math.floor(
        (canvasRect.current.height - dotDiameter.current) /
          (dotDiameter.current + dotGap)
      )
      const numCols = Math.floor(
        (canvasRect.current.width - dotDiameter.current) /
          (dotDiameter.current + dotGap)
      )
      const edgeOffset = dotRadius.current * 1.3
      let contentRect
      dotsArray.current = []

      if (contentRef.current) {
        contentRect = contentRef.current.map(item => {
          const rect = item.getBoundingClientRect()
          const yStart = rect.top - canvasRect.current.top - dotGap
          const yEnd = yStart + rect.height + dotGap * 2
          const xStart = rect.left - canvasRect.current.left - dotGap
          const xEnd = xStart + rect.width + dotGap * 2

          return {
            xStart,
            xEnd,
            yStart,
            yEnd,
          }
        })
      }
      for (let rowIndex = 0; rowIndex < numRows; rowIndex++) {
        for (let colIndex = 0; colIndex < numCols; colIndex++) {
          let show = true
          const x = gsap.utils.mapRange(
            0,
            numCols - 1,
            edgeOffset,
            canvasRect.current.width - edgeOffset,
            colIndex
          )

          const y = gsap.utils.mapRange(
            0,
            numRows - 1,
            edgeOffset,
            canvasRect.current.height - edgeOffset,
            rowIndex
          )

          if (contentRect) {
            contentRect.forEach(item => {
              const { xStart, xEnd, yStart, yEnd } = item

              if (x >= xStart && x <= xEnd && y >= yStart && y <= yEnd) {
                show = false
              }
            })
          }

          dotsArray.current.push({
            x,
            y,
            opacityCurrent: 0,
            opacityTarget: 0,
            show,
          })
        }
      }
    }

    setup()
  }, [$canvas, ctx, canvasDimensions])

  // Setup animation frame
  useEffect(() => {
    const draw = () => {
      if (!isInView.current || !canvasRect.current) return

      const dots = dotsArray.current

      if (!dots.length) return

      const lerpProgress = 0.07
      const lerpX = gsap.utils.interpolate(
        mousePosPrevious.current.x,
        mousePos.x,
        lerpProgress
      )
      const lerpY = gsap.utils.interpolate(
        mousePosPrevious.current.y,
        mousePos.y,
        lerpProgress
      )
      const relativeMousePos = {
        x: lerpX - canvasRect.current.left,
        y: lerpY - canvasRect.current.top,
      }
      const proximityThreshold = Math.min(0.04 * window.innerWidth, 80)

      ctx.clearRect(0, 0, $canvas.current.width, $canvas.current.height)

      for (let dotIndex = 0; dotIndex < dots.length; dotIndex++) {
        const dot = dots[dotIndex]
        const deltaX = Math.abs(dot.x - relativeMousePos.x)
        const deltaY = Math.abs(dot.y - relativeMousePos.y)
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2)

        // Randomly show and hide a dot
        if (
          shimmer &&
          dot.opacityCurrent <= 0.0001 &&
          Math.random() < shimmerThreshhold
        ) {
          dot.opacityTarget = 1
        }

        if (dot.opacityCurrent >= 0.9999) {
          dot.opacityTarget = 0
        }

        if (distance < proximityThreshold) {
          dot.opacityTarget = 1
        }

        const lerpOpacity = gsap.utils.interpolate(
          dot.opacityCurrent,
          dot.opacityTarget,
          lerpProgress * 2 * lerpOpacityScale
        )

        if (showBackgroundDots) {
          ctx.fillStyle = colors.grey
          ctx.beginPath()
          ctx.arc(dot.x, dot.y, dotRadius.current, 0, 2 * Math.PI)
          ctx.fill()
          ctx.closePath()
        }

        ctx.fillStyle = hexToRgbA(colors.green, !dot.show ? 0 : lerpOpacity)
        ctx.beginPath()
        ctx.arc(dot.x, dot.y, dotRadius.current, 0, 2 * Math.PI)
        ctx.fill()
        ctx.closePath()

        dot.opacityCurrent = lerpOpacity
      }

      mousePosPrevious.current = { x: lerpX, y: lerpY }
    }

    const render = () => {
      draw()
      animationFrame.current = window.requestAnimationFrame(render)
    }

    render()

    return () => {
      if (animationFrame.current) {
        window.cancelAnimationFrame(animationFrame.current)
      }
    }
  }, [
    $canvas,
    ctx,
    mousePos,
    showBackgroundDots,
    lerpOpacityScale,
    shimmer,
    shimmerThreshhold,
  ])

  return (
    <DotsMain ref={inViewRef}>
      <DotsCanvas ref={$canvas}></DotsCanvas>
    </DotsMain>
  )
}

Dots.propTypes = {
  content: PropTypes.array,
  showBackgroundDots: PropTypes.bool,
  shimmer: PropTypes.bool,
  shimmerAmount: PropTypes.number,
  lerpOpacityScale: PropTypes.number,
}

export default Dots
