import React from 'react'

const Logomark = ({ width = 45, height = 45, responsive = true }) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 45 45"
      aria-hidden="true"
      focusable="false"
      {...svgAttributes}
    >
      <rect width="45" height="45" fill="#00DE3E" rx="7" />
      <path
        fill="#FEFFF9"
        d="m24.76 13.674-2.125-2.583c-.03-.03-.03-.06-.06-.06-.06-.031-.09-.031-.12-.031s-.06 0-.09.03-.06.03-.06.061L13.06 22.302c-.03.03-.03.061-.06.092v.121c0 .03 0 .061.03.091 0 .03.03.061.06.091l9.245 11.212c.03.03.03.06.06.06.03.031.06.031.09.031s.06 0 .09-.03.06-.03.06-.061l2.124-2.583-7.27-8.872 7.27-8.78Z"
      />
      <path
        fill="#FEFFF9"
        d="m26.046 29.746 5.864-7.079c.03-.03.03-.06.06-.091 0-.03.03-.06.03-.122 0-.03 0-.06-.03-.121 0-.03-.03-.06-.06-.091l-2.154-2.613-5.984 7.322 2.274 2.795Zm.089-14.432-5.984 7.262 2.274 2.765 5.985-7.262-2.275-2.765Z"
      />
    </svg>
  )
}

export default Logomark
