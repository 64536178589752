export function disablePageScroll() {
  document.body.classList.add('disable-scroll')
}

export function enablePageScroll() {
  document.body.classList.remove('disable-scroll')
}

export function focusElement(el) {
  el.setAttribute('tabindex', 0)
  el.focus()
}

export function getResourcesTag(metadata) {
  const tag = metadata?.tags[0]?.name

  return tag ? tag.split(': ')[1] : tag
}
