import React, { forwardRef } from 'react'
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Label,
  TextBodyLarge,
  TextBody,
  TextBodySmall,
  TextBodyXSmall,
  TextBodyXXSmall,
  TextCta,
  HoldingsLabel,
} from '../../styles/vars/textStyles.style'

const ThisHeading1 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading1 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading1>
  )
)

const ThisHeading2 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading2 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading2>
  )
)

const ThisHeading3 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading3 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading3>
  )
)

const ThisHeading4 = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Heading4 ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Heading4>
  )
)

const ThisLabel = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <Label ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </Label>
  )
)

const ThisHoldingsLabel = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <HoldingsLabel
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </HoldingsLabel>
  )
)

const ThisTextBodyLarge = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodyLarge
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodyLarge>
  )
)

const ThisTextBody = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBody ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextBody>
  )
)

const ThisTextBodySmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodySmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodySmall>
  )
)

const ThisTextBodyXSmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodyXSmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodyXSmall>
  )
)

const ThisTextBodyXXSmall = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextBodyXXSmall
      ref={ref}
      as={as}
      color={color}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </TextBodyXXSmall>
  )
)

const ThisTextCta = forwardRef(
  ({ children, as, color, maxWidth, ...props }, ref) => (
    <TextCta ref={ref} as={as} color={color} maxWidth={maxWidth} {...props}>
      {children}
    </TextCta>
  )
)

export {
  ThisHeading1 as Heading1,
  ThisHeading2 as Heading2,
  ThisHeading3 as Heading3,
  ThisHeading4 as Heading4,
  ThisLabel as Label,
  ThisHoldingsLabel as HoldingsLabel,
  ThisTextBodyLarge as TextBodyLarge,
  ThisTextBody as TextBody,
  ThisTextBodySmall as TextBodySmall,
  ThisTextBodyXSmall as TextBodyXSmall,
  ThisTextBodyXXSmall as TextBodyXXSmall,
  ThisTextCta as TextCta,
}
