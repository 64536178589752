import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { ContainerMain } from './index.style'

const Container = forwardRef(
  ({ allowOverflow = true, fullWidth = false, children, ...props }, ref) => (
    <ContainerMain
      ref={ref}
      allowOverflow={allowOverflow}
      fullWidth={fullWidth}
      {...props}
    >
      {children}
    </ContainerMain>
  )
)

Container.propTypes = {
  allowOverflow: PropTypes.bool,
  fullWidth: PropTypes.bool,
  children: PropTypes.node,
}

export default Container
